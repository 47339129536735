<template>
  <div>
    <cropper></cropper>
  </div>
</template>

<script>

import Cropper from '@core/components/cx/cropper/index.vue'

export default {
  name: 'example',
  components: {
    Cropper,
  },
}
</script>

<style scoped>

</style>
