<template>
  <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <div class="card content">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <p>CrooperJS:</p>
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :src="imgSrc"
                preview=".preview"
                class="cropper-min-height"
              />
            </div>
            <div class="actions">
              <b-button-group vertical class="mr-2 mb-3">
                <div @click="zoom(0.2)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/general/gen035.svg" />
                  </span>
                </div>
                <div @click="zoom(-0.2)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/general/gen036.svg" />
                  </span>
                </div>
              </b-button-group>

              <b-button-group vertical class="mr-2 mb-3">
                <div @click="move(-10, 0)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/general/gen058.svg" />
                  </span>
                </div>
                <div @click="move(10, 0)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/general/gen057.svg" />
                  </span>
                </div>
              </b-button-group>

              <b-button-group vertical class="mr-2 mb-3">
                <div @click="move(0, -10)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/arrows/arr026.svg" />
                  </span>
                </div>
                <div @click="move(0, 10)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/arrows/arr027.svg" />
                  </span>
                </div>
              </b-button-group>

              <b-button-group vertical class="mr-2 mb-3">
                <div @click="rotate(90)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/arrows/arr055.svg" />
                  </span>
                </div>
                <div @click="rotate(-90)" class="btn bg-light-secondary btn-color-secondary btn-active-primary">
                  <span class="svg-icon">
                    <inline-svg src="media/icons/duotune/arrows/arr054.svg" />
                  </span>
                </div>
              </b-button-group>

              <div @click="cropImage" class="mr-2 mb-3 btn bg-light-secondary btn-color-secondary btn-active-primary">
                <span class="svg-icon">
                  <inline-svg src="media/icons/duotune/art/art006.svg" />
                 </span>
              </div>
              <div @click="reset" class="mr-2 mb-3 btn bg-light-secondary btn-color-secondary btn-active-primary">
                <span class="svg-icon">
                  <inline-svg src="media/icons/duotune/abstract/abs043.svg" />
                 </span>
              </div>
              <div @click="showFileChooser" class="mr-2 mb-3 btn bg-light-secondary btn-color-secondary btn-active-primary rotate-180">
                <span class="svg-icon">
                  <inline-svg src="media/icons/duotune/arrows/arr091.svg" />
                 </span>
              </div>
              <div @click="download" class="mr-2 mb-3 btn bg-light-secondary btn-color-secondary btn-active-primary">
                <span class="svg-icon">
                  <inline-svg src="media/icons/duotune/arrows/arr091.svg" />
                 </span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div style="min-height:209px">
              <p>Preview</p>
              <div class="w-100 cropped-img mt-2 preview secondary" />
            </div>
            <div>
              <p>Cropped Image</p>
              <div class="cropped-image secondary">
                <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                <div v-else class="crop-placeholder" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BButtonGroup,
} from 'bootstrap-vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'Cooper-1',
  components: {
    BButtonGroup,
    VueCropper,
  },
  data() {
    return {
      imgSrc: 'http://file.cloudxin.ltd/app-bgm/banner/1.jpg',
      cropImg: '',
      fileExtension: 'png',
    }
  },
  mounted() {
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg)
    },
    setImage(e) {
      const file = e.target.files[0]
      this.fileExtension = file.name.split('.').pop()
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    download() {
      if (this.cropImg) {
        let extention = this.fileExtension
        let href = this.cropImg
        let link = document.createElement('a')
        link.addEventListener(
          'click',
          () => {
            link.href = href
            link.download = 'cropper-img.' + extention
            // console.log(link.download);
          }, false,
        )
        link.click()
      } else {
        alert('Please, crop image before download it')
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
input[type='file'] {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
}

.actions {
  margin-top: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.cropped-image img {
  max-width: 100%;
}

.cropper-bg {
  background-repeat: repeat;
}

.cropper-min-height {
  min-height: 400px;
}
</style>
